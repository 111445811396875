import React, { useState, useEffect } from "react";
import {  Building2 } from "lucide-react";
import { useParams } from "react-router-dom";
import { getPublicTributeDetails } from "./service";
import logo_green from "./assets/logo_green.svg";
import { useNavigate } from 'react-router-dom';

const DefaultNpoLogo = () => (
  <div className="h-16 w-16 rounded-xl bg-teal-50/50 p-3 flex items-center justify-center">
    <Building2 className="w-full h-full text-[#2F4F4F]" />
  </div>
);

const SectionHeader = ({ children }) => (
  <div className="text-center mb-2">
    <span className="text-base italic text-gray-600">
      {children}
    </span>
  </div>
);

const PublicTribute = ({ tributeDetails }) => {
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  function generateDonationId(tributeDetails) {
    // Constants
    const PREFIX = 'THX';
    
    // Extract NPO abbreviation
    const npoAbbr = tributeDetails.npo.abbreviation;
    
    // Extract date from created_at
    const createdDate = new Date(tributeDetails.created_at);
    const day = createdDate.getDate().toString().padStart(2, '0');
    const month = (createdDate.getMonth() + 1).toString().padStart(2, '0');
    const dateComponent = `${day}${month}`;
    
    // Generate random 4-digit alphanumeric string
    function generateAlphaNumeric() {
      const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      let result = '';
      for (let i = 0; i < 4; i++) {
        result += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return result;
    }
    
    // Construct the donation ID
    const donationId = `${PREFIX}-${npoAbbr}-${dateComponent}-${generateAlphaNumeric()}`;
    
    return donationId;
  }



  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-white">
      {/* Main Container */}
      <div className="max-w-2xl mx-auto px-4 py-12 space-y-16">
        {/* Logo */}
        <div
          onClick={() => { navigate("/") }}
          className="flex justify-center cursor-pointer">
          <div className="flex flex-col items-center space-y-2">
            <img src={logo_green} alt="TinyTribute" className="h-12 w-12" />
            <div className="flex items-center text-xl">
              <span className="font-medium text-gray-600">Tiny</span>
              <span className="font-extrabold" style={{ color: '#2F4F4F' }}>Tribute</span>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="space-y-16">
          {/* In Honor Of Section */}
          <div className="text-center space-y-2">
            <SectionHeader>In Honor of</SectionHeader>
            <h1 className="text-4xl font-bold text-gray-900">
              {tributeDetails?.honoree_name}
            </h1>
            {tributeDetails?.honoree_company && (
              <p className="text-lg text-gray-600">
                {tributeDetails?.honoree_company}
              </p>
            )}
          </div>

          {/* Donated By Section */}
          <div className="text-center space-y-2">
            <SectionHeader>Donated by</SectionHeader>
            <h2 className="text-2xl font-semibold text-gray-900">
              {tributeDetails?.user?.name}
            </h2>
          </div>

          {/* Donation To Section */}
          <div className="text-center space-y-8">
            <div>
              <SectionHeader>Donation to</SectionHeader>
              <div className="mt-6 space-y-4">
                <a href={tributeDetails?.npo?.url} target="_blank" className="inline-block">
                  <div className="flex items-center justify-center shadow-lg">
                    {tributeDetails?.npo?.logoUrl ? (
                      <img
                        src={tributeDetails?.npo?.logoUrl}
                        alt={tributeDetails?.npo?.name}
                        className="h-20 w-20 object-contain rounded-xl p-3"
                      />
                    ) : (
                      <DefaultNpoLogo />
                    )}
                  </div>
                </a>

                <div className="text-center space-y-2">
                  <a href={tributeDetails?.npo?.url} target="_blank">
                    <h3 className="text-xl font-semibold text-gray-900 hover:underline">
                      {tributeDetails?.npo?.name}
                    </h3>
                  </a>
                  <p className="text-sm text-gray-600">
                    {tributeDetails?.npo?.description || "Empowering children's rights and creating lasting change"}
                  </p>
                </div>
              </div>
            </div>

            {/* ID and Timestamp */}
            <div className="text-sm text-gray-500 flex flex-row items-center justify-between">
              <p>ID: {generateDonationId(tributeDetails)}</p>
              <p>{formatDate(tributeDetails?.created_at)}</p>
            </div>
          </div>

          {/* Canvas Section */}
          {tributeDetails?.public_visibility === "full" &&
            tributeDetails?.tribute_canvas_data?.canvasImage && (
              <div className="pt-8">
                <div className="rounded-xl overflow-hidden">
                  <img
                    src={tributeDetails?.tribute_canvas_data?.canvasImage}
                    alt="Tribute message"
                    className="w-full object-contain"
                  />
                </div>
              </div>
            )}
        </div>
      </div>

      {/* Footer */}
      <div className="text-center pb-6 flex flex-row items-center justify-center">
        <p className="text-[10px] text-gray-500 mr-[4px]">Powered by</p>
        <div className="flex items-center cursor-pointer">
          <img
            src={logo_green}
            className="h-[14px] w-[14px] mr-1"
            alt="TinyTribute"
          />
          <span className="text-[14px] text-black font-semibold select-none">
            TinyTribute
          </span>
        </div>
      </div>
    </div>
  );
};

// Main container component with loading handling
const PublicTributeContainer = () => {
  const [tributeDetails, setTributeDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const { tributeId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getPublicTributeDetails({ tributeId });
        setTributeDetails(response);
      } catch (error) {
        console.error("Error fetching tribute details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [tributeId]);

  if (loading) return <Loader />;

  return <PublicTribute tributeDetails={tributeDetails} />;
};

const Loader = () => (
  <div className="flex justify-center items-center h-32">
    <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-teal-500"></div>
  </div>
);

export default PublicTributeContainer;