import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://u3hl4x0yy9.execute-api.ap-south-1.amazonaws.com';
const ENV_STAGE = process.env.REACT_APP_NODE_ENV || 'dev';


const apiClient = axios.create({
    baseURL: `${API_BASE_URL}/${ENV_STAGE}/api`,
    headers: {
        'Content-Type': 'application/json'
    }
});

// GET Tribute Details
export async function getPublicTributeDetails({ tributeId }) {
    try {
        const response = await apiClient.get(`/tribute-handler/getPublicTributeDetails?tribute_id=${tributeId}`);
        return response.data;
    } catch (error) {
        window.location.href = '/';
        alert(error.response.data.error)
    }
}

// POST Waitlist Handler
export async function waitlistSubscription({ email, name }) {
    try {
        const response = await apiClient.post('/public-handler/waitlistHandler', { email, name });
        return response.data;
    } catch (error) {
        alert(error.response.data.error)
    }
}
