import React from "react";
import logo_green from "./assets/logo_green.svg";
import { useNavigate, useLocation } from "react-router-dom";

function Header() {
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogoClick = () => {
    if (location.pathname !== "/") {
      navigate("/");
    }
  };

  return (
    <nav className="relative z-10 p-6">
      <div className="max-w-6xl mx-auto flex justify-between items-center">
        <div
          className="flex items-center space-x-2 cursor-pointer"
          onClick={handleLogoClick}
        >
          <img src={logo_green} className="h-[30px] w-[30px]" />
          <span className="text-[18px] text-black font-semibold select-none">
            TinyTribute
          </span>
        </div>
      </div>
    </nav>
  );
}

export default Header;
