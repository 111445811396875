import React, { useState } from 'react';
import { X } from 'lucide-react';
import { waitlistSubscription } from '../service';
import { isEmpty } from 'lodash';

const WaitlistModal = ({ isOpen, onClose, email, setEmail, loading, setLoading, setResponse, setIsSubscribed, response }) => {
  const [name, setName] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const r = await waitlistSubscription({ email, name });
    setResponse(r?.message);
    setLoading(false);
    setIsSubscribed(true);
    setEmail("");
    setName("");
    setTimeout(() => {
      onClose();
      setResponse(null)
    }, 2000);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-2 sm:p-4">
      <div className="relative bg-white rounded-xl w-full max-w-xl shadow-lg">
        <button 
          onClick={onClose}
          className="absolute right-3 top-3 sm:right-4 sm:top-4 text-gray-500 hover:text-gray-700"
        >
          <X className="w-4 h-4 sm:w-5 sm:h-5" />
        </button>

        <div className="p-4 sm:p-6 md:p-8">
          {response ? (
            <div className="text-center space-y-3 sm:space-y-4">
              <div className="mx-auto w-10 h-10 sm:w-12 sm:h-12 rounded-full bg-green-100 flex items-center justify-center">
                <svg
                  className="w-5 h-5 sm:w-6 sm:h-6 text-green-600"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>
              <h2 className="text-xl sm:text-2xl font-bold text-gray-900">{response}</h2>
              <p className="text-sm sm:text-base text-gray-600">
                We'll keep you updated on our progress.
              </p>
            </div>
          ) : (
            <>
              <h2 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-2 text-center">
                Sign Up for the Waitlist
              </h2>
              <p className="text-sm sm:text-base text-gray-600 text-center mb-6 sm:mb-8">
                Fill out the information, and be among the first ones to show
                gratitude that truly resonates.
              </p>

              <form onSubmit={handleSubmit} className="space-y-4 sm:space-y-6">
                <div>
                  <label 
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="John Doe"
                    className="w-full px-3 sm:px-4 py-2 sm:py-3 rounded-lg border border-gray-200 focus:outline-none focus:ring-2 focus:ring-[#2F4F4F] focus:bg-white text-sm sm:text-base"
                    required
                  />
                </div>

                <div>
                  <label 
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Email Address
                  </label>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="johndoe@gmail.com"
                    className="w-full px-3 sm:px-4 py-2 sm:py-3 rounded-lg border border-gray-200 focus:outline-none focus:ring-2 focus:ring-[#2F4F4F] focus:bg-white text-sm sm:text-base"
                    required
                  />
                </div>

                <button
                  type="submit"
                  disabled={loading}
                  className="w-full flex items-center justify-center px-4 sm:px-6 py-2.5 sm:py-3 text-sm sm:text-base font-semibold text-white bg-[#2F4F4F] rounded-lg transition-colors duration-200 hover:bg-[#1F3F3F] disabled:opacity-70"
                >
                  {loading ? (
                    <svg
                      className="animate-spin h-4 w-4 sm:h-5 sm:w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      aria-hidden="true"
                    >
                      <circle className="opacity-25" cx="12" cy="12" r="10" />
                      <path
                        className="opacity-75"
                        d="M4 12a8 8 0 018-8v2a6 6 0 100 12v2a8 8 0 01-8-8z"
                      />
                    </svg>
                  ) : (
                    "Submit"
                  )}
                </button>
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default WaitlistModal;