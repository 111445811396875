import React, { useEffect, useState } from 'react';

const testimonials = [
  {
    id: 1,
    name: "Abhiraj Singh Bhal",
    role: "CEO, Urban Company",
    image: "https://cdn.prod.website-files.com/672b9905ae5121c16c03c159/6731c2206de567986c279634_Abhiraj-min.avif"
  },
  {
    id: 2,
    name: "Alakh Pandey",
    role: "CEO, Physics Wallah",
    image: "https://cdn.prod.website-files.com/672b9905ae5121c16c03c159/6731c220c31de926723b6911_Ashish-1-min.avif"
  },
  {
    id: 3,
    name: "Nithin Kamath",
    role: "CEO, Zerodha",
    image: "https://cdn.prod.website-files.com/672b9905ae5121c16c03c159/6731c220ae61d840451e2cce_Nithin-min.avif"
  },
  {
    id: 4,
    name: "Ashish Kashyap",
    role: "Founder & CEO, INDMoney",
    image: "https://cdn.prod.website-files.com/672b9905ae5121c16c03c159/6731c2204019493ea13733a7_Ashish-min.avif"
  },
  {
    id: 5,
    name: "Bhavesh Agarwal",
    role: "CEO, Ola",
    image: "https://cdn.prod.website-files.com/672b9905ae5121c16c03c159/6731c2208efadf888dfd7e77_Bhavesh-min.avif"
  },
  {
    id: 6,
    name: "Vargab Bakshi",
    role: "Country Head, Wix",
    image: "https://cdn.prod.website-files.com/672b9905ae5121c16c03c159/6731c220d5040365b179bf7a_Vargab-min.avif"
  },
  {
    id: 7,
    name: "Sraddha Sharma",
    role: "CEO, Yourstory",
    image: "https://cdn.prod.website-files.com/672b9905ae5121c16c03c159/6731c220af81aa1052d8a346_Sraddha-min.avif"
  },
  {
    id: 8,
    name: "Varun Mayya",
    role: "Influencer",
    image: "https://cdn.prod.website-files.com/672b9905ae5121c16c03c159/6731c21f237bb04d91428d8e_Varun-min.avif"
  },
  {
    id: 9,
    name: "Ivan Zhao",
    role: "CEO, Notion",
    image: "https://cdn.prod.website-files.com/672b9905ae5121c16c03c159/6731c21fc31de926723b68ed_Ivan-min.avif"
  },
  {
    id: 10,
    name: "Ankur Warikoo",
    role: "Influencer & Entrepreneur",
    image: "https://cdn.prod.website-files.com/672b9905ae5121c16c03c159/6731c21fc9ab3a07a8807f91_Ankur-min.avif"
  }
];

const TestimonialsSection = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [cardWidth, setCardWidth] = useState(300);

  // Update card width based on screen size
  useEffect(() => {
    const updateCardWidth = () => {
      if (window.innerWidth < 640) { // sm
        setCardWidth(220);
      } else if (window.innerWidth < 1024) { // md
        setCardWidth(260);
      } else { // lg and above
        setCardWidth(300);
      }
    };

    updateCardWidth();
    window.addEventListener('resize', updateCardWidth);
    return () => window.removeEventListener('resize', updateCardWidth);
  }, []);

  // Scrolling animation
  useEffect(() => {
    const interval = setInterval(() => {
      setScrollPosition((prev) => {
        const newPosition = prev + 1;
        if (newPosition >= testimonials.length * (cardWidth + 24)) { // cardWidth + gap
          return 0;
        }
        return newPosition;
      });
    }, 50);

    return () => clearInterval(interval);
  }, [cardWidth]);

  return (
    <section className="py-12 md:py-16 lg:py-24 bg-white overflow-hidden">
      <div className="container mx-auto px-4 md:px-6">
        <h2 className="text-3xl md:text-4xl lg:text-[48px] font-bold font-serif text-center mb-6">
          Bringing <span className="text-[#2F4F4F]">Smiles</span> On Familiar Faces
        </h2>
        <p className="text-center text-sm md:text-base text-gray-700 mb-8 md:mb-12 lg:mb-16 max-w-4xl mx-auto px-1">
        TinyTribute began with our co-founder sharing 40+ handwritten tributes with people he admired. Their responses inspired us to build an iPad app that makes the process quicker without losing its heartfelt impact. Now, it takes just a couple of minutes, creating lasting connections in a fraction of the time.
        </p>

        <div className="relative">

          <div className="overflow-hidden">
            <div
              className="flex gap-4 md:gap-6 px-4 will-change-transform"
              style={{
                transform: `translate3d(-${scrollPosition}px, 0, 0)`,
                WebkitBackfaceVisibility: 'hidden',
                WebkitPerspective: 1000,
                backfaceVisibility: 'hidden',
                perspective: 1000,
                transition: 'transform 1000ms linear'
              }}
            >
              {[...testimonials, ...testimonials].map((item, index) => (
                <div
                  key={`${item.id}-${index}`}
                  className="flex-none"
                  style={{
                    width: `${cardWidth}px`,
                    WebkitBackfaceVisibility: 'hidden',
                    WebkitPerspective: 1000
                  }}
                >
                  <div className="rounded-lg overflow-hidden mb-3 md:mb-4 shadow-sm hover:shadow-md transition-shadow duration-300">
                    <img
                      src={item.image}
                      alt={item.name}
                      className="w-full object-cover transition-transform duration-300 hover:scale-105"
                      style={{
                        height: `${cardWidth}px`,
                        WebkitTransform: 'translateZ(0)',
                        WebkitBackfaceVisibility: 'hidden'
                      }}
                    />
                  </div>
                  <h3 className="text-lg md:text-xl font-semibold font-serif text-[#2F4F4F] truncate">
                    {item.name}
                  </h3>
                  <p className="text-sm md:text-base text-gray-600">{item.role}</p>
                </div>
              ))}
            </div>
          </div>


        </div>
      </div>
    </section>
  );
};



export default TestimonialsSection;