import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./Header";
import PublicTributeContainer from "./PublicTribute";
import Home from "./home/Index";

function AppRouter() {
  return (
    <Router>
      <Routes>
        {/* Route for the landing page */}
        <Route path="/" element={<Home />} />

        {/* Dynamic route for PublicTribute */}
        <Route path="/t/:tributeId" element={<PublicTributeContainer />} />
      </Routes>
    </Router>
  );
}

export default AppRouter;
