import React from 'react';
import { Gift, Minus, Check } from 'lucide-react';

const ComparisonSection = () => {
  const traditionalPoints = [
    "Quick text or email",
    "Generic, easily overlooked",
    "Fleeting acknowledgment",
    "Easily forgotten",
    "Thanks without action"
  ];

  const tinyTributePoints = [
    "Handwritten note that feels truly personal",
    "Unique, memorable, and crafted just for them",
    "A gesture that leaves a lasting impact",
    "A tribute they'll remember and cherish",
    "Gratitude that also supports a meaningful cause"
  ];

  return (
    <section className="py-6 md:py-8 lg:py-10 px-4 md:px-6">
      <div className="container mx-auto p-6 md:p-12 lg:p-24 bg-[#FAF6F2] rounded-xl md:rounded-2xl lg:rounded-3xl">
        <h2 className="text-3xl md:text-4xl lg:text-[48px] font-bold font-serif text-center mb-8 md:mb-12 lg:mb-16 flex flex-col gap-0">
          <span>Why TinyTribute Is More</span>
          <div className='my-[2px] md:my-3'></div>
          <span>Than A <span className="text-[#2F4F4F]">"Thank You"</span></span>
        </h2>

        <div className="flex flex-col md:flex-row gap-6 md:gap-8 max-w-5xl mx-auto">
          {/* Traditional Thank You Card */}
          <div className="flex-1 bg-white rounded-lg p-6 md:p-8 shadow-sm hover:shadow-md transition-shadow duration-300">
            <div className="mb-4 md:mb-6">
              <Gift className="w-10 h-10 md:w-[52px] md:h-[52px] text-gray-600" />
            </div>
            <h3 className="text-lg md:text-xl font-serif text-gray-800 mb-4 md:mb-6 font-semibold">
              Traditional Thank You
            </h3>
            <div className="space-y-3 md:space-y-4">
              {traditionalPoints.map((point, index) => (
                <div key={index} className="flex items-start md:items-center gap-2 md:gap-3">
                  <Minus className="w-4 h-4 md:w-5 md:h-5 text-gray-400 flex-shrink-0 mt-1 md:mt-0" />
                  <span className="text-sm md:text-base text-gray-600">{point}</span>
                </div>
              ))}
            </div>
          </div>

          {/* TinyTribute's Way Card */}
          <div className="flex-1 bg-[#2F4F4F] rounded-lg p-6 md:p-8 shadow-sm hover:shadow-md transition-shadow duration-300">
            <div className="mb-4 md:mb-6">
              <div className="w-10 h-10 md:w-[50px] md:h-[50px]">
                <div className="icon-1x1-medium w-embed">
                  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 48 48" fill="none">
                    <path d="M26.6375 17.6287L33.7818 10.4843L37.5132 14.2157L30.3689 21.3601H47.9976C46.7773 10.1343 37.8633 1.2203 26.6375 0V17.6287Z" fill="white"></path>
                    <path d="M17.6287 21.3601L10.4843 14.2157L14.2157 10.4843L21.3601 17.6287V0C10.1343 1.2203 1.2203 10.1343 0 21.3601H17.6287Z" fill="white"></path>
                    <path d="M21.3601 30.3713L14.2157 37.5156L10.4843 33.7843L17.6287 26.6399H4.33979e-07C1.2203 37.8657 10.1343 46.7797 21.3601 48V30.3713Z" fill="white"></path>
                    <path d="M30.3713 26.6375L37.5156 33.7818L33.7843 37.5132L26.6399 30.3689V47.9976C37.8657 46.7773 46.7797 37.8633 48 26.6375H30.3713Z" fill="white"></path>
                  </svg>
                </div>
              </div>
            </div>
            <h3 className="text-lg md:text-xl font-serif text-white mb-4 md:mb-6 font-semibold">
              TinyTribute's Way
            </h3>
            <div className="space-y-3 md:space-y-4">
              {tinyTributePoints.map((point, index) => (
                <div key={index} className="flex items-start md:items-center gap-2 md:gap-3 group">
                  <Check className="w-4 h-4 md:w-5 md:h-5 text-white flex-shrink-0 mt-1 md:mt-0 transform group-hover:scale-110 transition-transform duration-200" />
                  <span className="text-sm md:text-base text-white/90 group-hover:text-white transition-colors duration-200">{point}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ComparisonSection;