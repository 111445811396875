import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const ngos = [
  {
    id: 1,
    name: "Akshaya Patra",
    description: "Providing mid-day meals to millions of children",
    image: "https://cdn.prod.website-files.com/672b9905ae5121c16c03c159/672f1b9e90577b7ddcf3a89b_Akshaya%20Patra-min.avif",
    website : "https://www.akshayapatra.org/"
  },
  {
    id: 2,
    name: "GiveIndia",
    description: "Supporting multiple causes across India",
    image: "https://cdn.prod.website-files.com/672b9905ae5121c16c03c159/672f22456bcc9582e2184332_GiveIndia-min.avif",
     website : "https://www.giveindia.org/"
  },
  {
    id: 3,
    name: "CRY",
    description: "Ensuring happy, healthy, and creative childhoods",
    image: "https://cdn.prod.website-files.com/672b9905ae5121c16c03c159/672f1b9e79488d86e2efb06a_CRY-min.avif",
     website : "https://www.cry.org/"
  },
  {
    id: 4,
    name: "Goonj",
    description: "Disaster relief and rural development",
    image: "https://cdn.prod.website-files.com/672b9905ae5121c16c03c159/672f1b9e90577b7ddcf3a89b_Akshaya%20Patra-min.avif",
     website : "https://goonj.org/"
  },
  {
    id: 5,
    name: "Pratham",
    description: "Quality education for underprivileged children",
    image: "https://cdn.prod.website-files.com/672b9905ae5121c16c03c159/672f1b9e7d623f0e2c99f7c0_Pratham-min.avif",
     website : "https://www.pratham.org/"
  },
  {
    id: 6,
    name: "HelpAge India",
    description: "Serving elderly in need",
    image: "https://cdn.prod.website-files.com/672b9905ae5121c16c03c159/672f2243782ca88f7fcd8b1d_HelpAge%20India-min.avif",
     website : "https://www.helpageindia.org/"
  }
];


const NGOPartnersSection = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [cardsToShow, setCardsToShow] = useState(3);
  const [isMobile, setIsMobile] = useState(false);

  // Handle responsive card display
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 640) {
        setCardsToShow(1);
        setIsMobile(true);
      } else if (window.innerWidth < 1024) {
        setCardsToShow(2);
        setIsMobile(false);
      } else {
        setCardsToShow(3);
        setIsMobile(false);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handlePrevious = () => {
    setCurrentIndex((prev) => (prev > 0 ? prev - 1 : 0));
  };

  const handleNext = () => {
    setCurrentIndex((prev) =>
      prev < ngos.length - cardsToShow + 1 ? prev + 1 : prev
    );
  };

  // Touch handling for mobile
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  const handleTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > 50;
    const isRightSwipe = distance < -50;

    if (isLeftSwipe && currentIndex < ngos.length - cardsToShow) {
      handleNext();
    }
    if (isRightSwipe && currentIndex > 0) {
      handlePrevious();
    }

    setTouchEnd(null);
    setTouchStart(null);
  };


  return (
    <section className="py-12 md:py-16 lg:py-24 bg-[#E6EDED]">
      <div className="container mx-auto px-4 md:px-6 lg:px-[150px]">
        <h2 className="text-3xl md:text-4xl lg:text-[48px] font-serif mb-6 md:mb-[40px] font-bold">
          <span className="text-[#2F4F4F]">Gratitude</span> That Lasts
        </h2>

        <div className="max-w-3xl mb-8 md:mb-16">
          <p className="text-base md:text-lg text-gray-700 mb-4 md:mb-6">
            Sometimes, a simple thank-you doesn't feel like enough. TinyTribute lets you express gratitude that
            truly resonates by supporting causes that matter to the people who inspire you. When you extend your
            appreciation this way, you're not only honoring them but also creating meaningful change.
          </p>

          <p className="text-base md:text-lg text-gray-700 mb-4 md:mb-6">
            Choose a cause that speaks to them from our curated list of NGOs, and help make a difference in their
            name.
          </p>

          <p className="text-base md:text-lg text-gray-700">
            Each tribute supports food, education, and hope for those who need it most.
          </p>
        </div>

        <div className="relative">
          {/* Cards Container */}
          <div className="overflow-hidden py-4 px-2 h-fit">
            <div
              className="flex gap-4 md:gap-6 transition-transform duration-500 h-full mx-2"
              style={{
                transform: `translateX(-${
                  currentIndex * (100 / Math.max(cardsToShow, 1)) + 
                  (currentIndex === ngos.length - cardsToShow ? 
                    (isMobile ? 30 : 2) // More space on mobile
                    : 0)
                }%)`,
              }}
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}
            >
              {ngos.map((ngo) => (
                <NGOCard
                  key={ngo.id}
                  ngo={ngo}
                />
              ))}
            </div>
          </div>

          {/* Navigation Buttons - Enhanced */}
          <div className="flex justify-center md:justify-left gap-3 md:gap-4 mt-6 md:mt-8">
            <button
              onClick={handlePrevious}
              disabled={currentIndex === 0}
              className="p-2 md:p-3 rounded-full border-2 border-[#2F4F4F] text-[#2F4F4F] hover:bg-[#2F4F4F] hover:text-white transition-all duration-300 disabled:opacity-50 disabled:cursor-not-allowed transform hover:-translate-y-0.5 active:translate-y-0"
            >
              <ChevronLeft className="w-5 h-5 md:w-6 md:h-6" />
            </button>
            <button
              onClick={handleNext}
              disabled={currentIndex >= ngos.length - cardsToShow}
              className="p-2 md:p-3 rounded-full border-2 border-[#2F4F4F] text-[#2F4F4F] hover:bg-[#2F4F4F] hover:text-white transition-all duration-300 disabled:opacity-50 disabled:cursor-not-allowed transform hover:-translate-y-0.5 active:translate-y-0"
            >
              <ChevronRight className="w-5 h-5 md:w-6 md:h-6" />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NGOPartnersSection;

const NGOCard = ({ ngo }) => {
  return (
<div className="flex-none w-full md:w-[calc(50%-12px)] lg:w-[calc(33.333%-16px)] h-[300px]">
      <a href={ngo?.website} target='_blank'>
      <div className="group relative rounded-xl overflow-hidden h-full flex flex-col shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1">
        {/* Image Container - Fixed aspect ratio */}
        <div className="relative aspect-video overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-transparent to-transparent z-10"></div>
          <img
            src={ngo.image}
            alt={ngo.name}
            className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-110"
            loading="lazy"
          />
          {/* Overlay content */}
          <div className="absolute bottom-0 left-0 right-0 p-4 md:p-6 z-20 transform translate-y-2 opacity-0 group-hover:translate-y-0 group-hover:opacity-100 transition-all duration-300">
            <p className="text-white/90 text-sm md:text-base font-light line-clamp-2">
              {ngo.description}
            </p>
          </div>
        </div>
        
        {/* Text content - Flex grow to fill remaining height */}
        <div className="flex-1 p-4 md:p-5 bg-white flex flex-col">
          <h3 className="text-lg md:text-xl font-semibold font-serif text-[#2F4F4F] mb-2 group-hover:text-[#3D6363] transition-colors">
            {ngo.name}
          </h3>
          <p className="text-sm md:text-base text-gray-600 line-clamp-3">
            {ngo.description}
          </p>
        </div>

        {/* Decorative border effect */}
        <div className="absolute bottom-0 left-0 w-full h-1 bg-gradient-to-r from-[#2F4F4F]/60 to-[#2F4F4F] transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></div>
      </div>
      </a>
    </div>
  );
};

