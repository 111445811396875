import React from 'react';
import { ArrowRight } from 'lucide-react';

const CTASection = ({ setIsModalOpen }) => {
  return (
    <section className="py-12 md:py-16 lg:py-24 bg-[#FAF9F9] relative">
      <div className="container mx-auto px-4 md:px-6 lg:px-[150px]">
        <div className="flex flex-col lg:flex-row items-center justify-between gap-8 md:gap-12 lg:gap-16">
          {/* Left Content */}
          <div className="lg:w-1/2 text-center lg:text-left">
            {/* <h2 className="text-3xl md:text-4xl lg:text-[48px] font-bold font-serif mb-4 md:mb-6">
              Be among the first to
              show <span className="text-[#2F4F4F]">gratitude that
              truly resonates.</span>
            </h2> */}
            <h2 className="text-3xl md:text-4xl lg:text-[48px] font-bold font-serif mb-4 md:mb-6 leading-tight lg:leading-snug">
              Be among the first to show <span className="text-[#2F4F4F]">gratitude that truly resonates.</span>
            </h2>
            <p className="text-gray-700 text-base md:text-lg mb-6 md:mb-8 max-w-lg mx-auto lg:mx-0">
              Join the TinyTribute waitlist to make your appreciation meaningful and
              lasting.
            </p>
            <button
              onClick={() => { setIsModalOpen(true) }}
              className="bg-[#2F4F4F] text-white px-6 md:px-8 py-2.5 md:py-3 rounded flex items-center gap-2 hover:bg-[#3D6363] transition-all duration-300 mx-auto lg:mx-0 hover:shadow-lg active:transform active:scale-95"
            >
              <span className="text-base md:text-lg">Join the Waitlist</span>
              <ArrowRight className="w-4 h-4 md:w-5 md:h-5 transform group-hover:translate-x-1 transition-transform" />
            </button>
          </div>

          {/* Right Content - Notes Image */}
          <div className="lg:w-1/2 w-full max-w-md lg:max-w-xl mx-auto lg:mx-0">
            <div className="relative">
              {/* Add a subtle gradient overlay */}
              <div className="absolute inset-0 bg-gradient-to-t from-[#FAF9F9]/20 to-transparent rounded-lg"></div>

              {/* Main image with enhanced styling */}
              <img
                src="https://cdn.prod.website-files.com/672b9905ae5121c16c03c159/6731f2abb00a9630aa3edbc2_this%20one%20(1)-min.avif"
                alt="Handwritten thank you notes"
                className="w-full rounded-lg transition-shadow duration-300"
                loading="lazy"
              />

              {/* Optional: Decorative elements */}
              <div className="absolute -top-4 -right-4 w-24 h-24 bg-[#2F4F4F]/5 rounded-full -z-10 hidden md:block"></div>
              <div className="absolute -bottom-4 -left-4 w-32 h-32 bg-[#2F4F4F]/5 rounded-full -z-10 hidden md:block"></div>
            </div>
          </div>
        </div>

        {/* Footer with improved mobile styling */}
        <div className="mt-12 md:mt-16 lg:mt-24 text-xs md:text-sm text-gray-500 text-center lg:text-left border-t border-gray-200 pt-6">
          <div className="flex flex-col md:flex-row justify-between items-center gap-4">
            <p>© 2024 TINY TRIBUTE. All rights reserved.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CTASection;