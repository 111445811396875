import React, { useEffect, useState } from 'react';
import TestimonialsSection from './testimonial-section';
import NGOPartnersSection from './ngo-partners-section';
import ComparisonSection from './comparision-section';
import CTASection from './cta-section';
import WaitlistModal from './waitlist-form';

const Navbar = ({ setIsModalOpen }) => {
  return (
    <nav className="fixed top-0 left-0 right-0 bg-white/90 backdrop-blur-sm z-50 py-3">
      <div className="container mx-auto px-4 md:px-6 lg:px-[150px] flex justify-between items-center">
        <div className="flex items-center">
          <img
            src="https://cdn.prod.website-files.com/672b9905ae5121c16c03c159/672e75cfb2e18e886227f49d_Frame.svg"
            alt="TinyTribute"
            className="h-6 md:h-7"
          />
        </div>
        <button
          onClick={() => { setIsModalOpen(true) }}
          className="bg-[#2F4F4F] text-white px-4 hidden md:block md:px-6 py-2 text-sm md:text-base rounded hover:bg-[#3D6363] transition-colors">
          Join the Waitlist
          <span className="ml-2">→</span>
        </button>
      </div>
    </nav>
  );
};

const RotatingWords = () => {
  const words = ['inspired', 'helped', 'mentored', 'motivated'];
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prev) => (prev + 1) % words.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <span className="relative inline-block">
      <style jsx>{`
        @keyframes fadeOut {
          0% {
            opacity: 1;
            transform: translateY(0);
          }
          100% {
            opacity: 0;
            transform: translateY(-20px);
          }
        }

        @keyframes fadeIn {
          0% {
            opacity: 0;
            transform: translateY(20px);
          }
          100% {
            opacity: 1;
            transform: translateY(0);
          }
        }

        .word-enter {
          animation: fadeIn 0.4s ease-out forwards;
        }

        .word-exit {
          animation: fadeOut 0.4s ease-out forwards;
        }
      `}</style>

      <span className="relative inline-block">
        {words.map((word, i) => (
          <span
            key={i}
            className={`absolute top-0 left-0 text-[#3D6363] font-medium whitespace-nowrap ${
              i === index ? 'word-enter' : 'word-exit'
            }`}
            style={{
              opacity: i === index ? 1 : 0,
              display: i === index || i === ((index - 1 + words.length) % words.length) ? 'inline-block' : 'none'
            }}
          >
            {word}
          </span>
        ))}

        {/* Invisible text to maintain space of current word */}
        <span className="invisible whitespace-nowrap">
          {words[index]}
        </span>
      </span>
    </span>
  );
};

const HeroSection = ({ setIsModalOpen }) => {

  return (
    <div className="min-h-screen bg-[#E6EDED] pt-20">
      <div className="container mx-auto px-4 md:px-6 lg:px-[80px] 2xl:px-[150px] flex flex-col lg:flex-row items-center justify-between">
        {/* Left Content */}
        <div className="w-full lg:w-1/2 mb-12 lg:mb-0 text-left">
          <h1 className="text-4xl md:text-5xl lg:text-[64px] font-serif mb-4 md:mb-6 font-bold">
            Say{' '}
            <span className="italic relative inline-block">
              <span className="relative">more</span>
            </span>{' '}
            than
            <br />
            <span className="text-[#2F4F4F]">"thank you"</span>
          </h1>

          <p className="text-gray-700 text-base md:text-lg mb-6 md:mb-8 max-w-lg lg:mx-0 leading-normal">
            Make a tribute donation in honor of someone who{' '}
            <RotatingWords />
            <span className="hidden md:inline">{' '}</span>
            <br className="hidden md:block" />
            <span className="md:inline">{' '}</span>
            you and share it with a handwritten note—all in just a few minutes.
          </p>
          
          <button
            onClick={() => { setIsModalOpen(true) }}
            className="bg-[#2F4F4F] w-full md:w-fit text-white px-6 md:px-8 py-2.5 md:py-3 text-sm md:text-base rounded hover:bg-[#3D6363] transition-colors">
            Join the Waitlist
            <span className="ml-2">→</span>
          </button>
        </div>

        {/* Right Content - Tablet Image */}
        <div className="w-full lg:w-1/2 px-4 lg:px-0">
          <div className="relative">
            <img
              src="https://s3.ap-south-1.amazonaws.com/tinytribute.com-production-media-assets/ipad+with+stylus.png"
              alt="Tablet with handwritten note"
              className="w-full max-w-md mx-auto lg:ml-auto drop-shadow-2xl"
            />
          </div>
        </div>
      </div>

      {/* How it Works Section */}
      <div className="container mx-auto px-4 py-16 md:py-24">
        <h2 className="text-3xl md:text-4xl lg:text-[48px] font-bold font-serif text-center mb-3">
          How Does <span className="text-[#2F4F4F]">TinyTribute</span> Work?
        </h2>
        <p className="text-center text-gray-700 mb-12 md:mb-16">Send a tribute in under 30 seconds.</p>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-8 max-w-6xl mx-auto">
          {steps.map((step, index) => (
            <StepCard key={index} {...step} />
          ))}
        </div>
      </div>
    </div>
  );
};

const StepCard = ({ icon, title, description }) => {
  return (
    <div className="bg-white rounded-lg p-4 md:p-6 py-6 md:py-8 text-center">
      <img
        src={icon}
        className="mb-4 md:mb-6"
      />
      <h3 className="text-lg md:text-xl font-serif mb-3 md:mb-4 font-bold">{title}</h3>
      <p className="text-gray-700 text-sm md:text-base">{description}</p>
    </div>
  );
};

const steps = [
  {
    icon: "https://s3.ap-south-1.amazonaws.com/tinytribute.com-production-media-assets/Icon+1.png",
    title: "Pick a Cause They'll Love",
    description: "Choose from trusted NGOs like Akshaya Patra and CRY to make an impact in their honor."
  },
  {
    icon: "https://s3.ap-south-1.amazonaws.com/tinytribute.com-production-media-assets/Icon+2.png",
    title: "Add Your Personal Touch",
    description: "Use your stylus to craft a handwritten message that shows gratitude words alone can't."
  },
  {
    icon: "https://s3.ap-south-1.amazonaws.com/tinytribute.com-production-media-assets/Icon+3.png",
    title: "Share the Tribute",
    description: "Send your personalized tribute—supporting change and creating a memorable moment."
  }
];

export default function Home() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);

  return (
    <main className="overflow-x-hidden">
      <Navbar setIsModalOpen={setIsModalOpen} />
      <HeroSection setIsModalOpen={setIsModalOpen} />
      <TestimonialsSection />
      <NGOPartnersSection />
      <ComparisonSection />
      <CTASection setIsModalOpen={setIsModalOpen} />

      <WaitlistModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        email={email}
        setEmail={setEmail}
        loading={loading}
        setLoading={setLoading}
        setResponse={setResponse}
        setIsSubscribed={setIsSubscribed}
        response={response}
      />
    </main>
  );
}